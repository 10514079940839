import React from 'react'
import Bredcom from '../Bredcom/Main'
import First from '../Mencource/First'

function Main() {
  return (
    <>
        <Bredcom
          title="Courses Details" 
          subtitle="Home"
          subtitledown="Pricing"
          />
        <First/>
    </>
  )
}

export default Main