import React from 'react'
import Bredcom from '../../Bredcom/Main'
import First from '../../Pages/Menteam/First'

function Main() {
  return (
    <>
      <Bredcom
      title="Team" 
      subtitle="Home"
      subtitledown="Pricing" />
      <First/>
    </>
  )
}

export default Main