import React from 'react'
import Bredcom from '../../components/Bredcom/Main'
import First from '../Menblogdeatils/First'

function Main() {
  return (
    <>
        <Bredcom
         title="Blog Deatils" 
         subtitle="Home"
         subtitledown="Blog Deatils"
         />
        <First/>
    </>
  )
}

export default Main