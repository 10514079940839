import React from 'react'
import Bredcom from '../Bredcom/Main'
import First from '../Menteemdeatils/First'

function Main() {
  return (
    <>
        <Bredcom
        title="Team Details" 
        subtitle="Home"
        subtitledown="Pricing" />
        
        <First/>
    </>
  )
}

export default Main