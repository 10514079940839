import React from 'react'
import Bredcom from '../../Bredcom/Main'
import First from '../../Pages/Menshop/First'

function Main() {
  return (
    <>
        <Bredcom
        title="Shop" 
        subtitle="Home"
        subtitledown="Pricing"
        />
        <First/>
    </>
  )
}

export default Main