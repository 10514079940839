import React from 'react'
import Bredcom from '../Bredcom/Main'
import Design from '../Mencoursesdetails/Design'

function Main() {
  return (
    <>
        <Bredcom
         title="Courses Details" 
         subtitle="Home"
         subtitledown="Pricing"
         />
        <Design/>
    </>
  )
}

export default Main