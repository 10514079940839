import React from 'react'
import Bredcom from '../../../components/Bredcom/Main'
import First from '../../../components/Pages/MenFaq/First'

function Main() {
  return (
    <>
        <Bredcom
        title="Faq" 
        subtitle="Home"
        subtitledown="Faq"
        />
        <First/>
    </>
  )
}

export default Main