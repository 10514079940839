import React from 'react'
import Bredcom from '../../Bredcom/Main'
import First from '../Menshopdeatils/First'

function Main() {
  return (
    <>
        <Bredcom
        title="Shop Details" 
        subtitle="Home"
        subtitledown="Pricing"
        />
        <First/>
    </>
  )
}

export default Main