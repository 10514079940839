import React from 'react'
import Bredcom from '../Bredcom/Main'
import First from '../Meneventsdetails/First'

function Main() {
  return (
    <>
        <Bredcom
        title="Events Details" 
        subtitle="Home"
        subtitledown="Blog Deatils"
        />
        <First/>
    </>
  )
}

export default Main