import React from 'react'
import Bredcom from '../../Bredcom/Main'
import First from '../../Pages/MenPricing/First'

function Main() {
  return (
    <>
        <Bredcom
         title="Pricing" 
         subtitle="Home"
         subtitledown="Pricing"
         />
        <First/>
    </>
  )
}

export default Main